import Main from 'v/main/main.vue'
// import parentView from '@/components/parent-view'

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
      hideInMenu: true
    },
    component: () => import ('v/login/login.vue')
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   meta: {
  //     title: 'Register - 注册',
  //     hideInMenu: true
  //   },
  //   component: () => import ('@/app/view/register/register.vue'),
  // },
  {
    path: '/',
    name: 'home',
    component: Main,
    redirect: '/index',
    meta: {
      notCache: true,
      keepAlive: true
    },
    children: [
      // {
      //   path: '/index',
      //   name: 'index',
      //   meta: {
      //     title: '首页',
      //     keepAlive: true
      //   },
      //   component: () => import ('v/index/index.vue')
      // }
    //   {
    //     path: '/detail/:itemid',
    //     name: 'detail',
    //     component: () => import ('@/app/view/detail/detail.vue'),
    //     meta: {
    //       title: '信息详情',
    //       icon: 'ios-cog',
    //       keepAlive: false
    //     }
    //   },
    //     {
    //         path: '/detailbelow/:itemid',
    //         name: 'detailbelow',
    //         component: () => import ('@/app/view/detail/detailbelow.vue'),
    //         meta: {
    //             title: '信息详情',
    //             icon: 'ios-cog',
    //             keepAlive: false
    //         }
    //     },
    //   {
    //     path: '/procategory/:categoryid',
    //     name: 'procategory',
    //     meta: {
    //       title: '分类产品',
    //       keepAlive: true
    //     },
    //     component: () => import ('@/app/view/productcategory/productcategorys.vue')
    //   },
    ]
  },
    {
        path: '/index',
        name: 'index',
        meta: {
            title: '首页',
            keepAlive: true
        },
        component: () => import ('v/index/index.vue')
    },
    {
        path: '/ulist',
        name: 'ulist',
        component: () => import ('v/userlist/list.vue'),
        meta: {
            title: '会员信息',
            icon: 'ios-cog',
            keepAlive: false
        }
    },
    {
      path: '/uplist',
      name: 'uplist',
      component: () => import ('v/donation/dplist.vue'),
      meta: {
        title: '项目捐款',
        icon: 'ios-cog',
        keepAlive: false
      }
    },
    {
      path: '/udplist',
      name: 'udplist',
      component: () => import ('v/user/uplist.vue'),
      meta: {
        title: '项目捐款',
        icon: 'ios-cog',
        keepAlive: false
      }
    },

    {
      path: '/udlist',
      name: 'udlist',
      component: () => import ('v/donation/list.vue'),
      meta: {
        title: '会员捐款',
        icon: 'ios-cog',
        keepAlive: false
      }
    },
    {
      path: '/dulist/:donationid',
      name: 'dulist',
      component: () => import ('v/donation/donation_userlist.vue'),
      meta: {
        title: '会员捐款',
        icon: 'ios-cog',
        keepAlive: false
      }
    },
    {
      path: '/news/:newsid',
      name: 'news',
      component: () => import ('v/news/news.vue'),
      meta: {
        title: '资讯',
        icon: 'ios-cog',
        keepAlive: false
      }
    },
    {
        path: '/user',
        name: 'user',
        component: () => import ('v/user/user.vue'),
        meta: {
          title: 'User',
          icon: 'ios-cog',
          keepAlive: false
        }
    },
  {
    path: '/userdonationlist/:userid',
    name: 'userdonationlist',
    component: () => import ('v/user/userdonations.vue'),
    meta: {
      title: '用户捐款',
      icon: 'ios-cog',
      keepAlive: false
    }
  }
]
