<template>
  <div id='app' style="overflow: hidden">
    <keep-alive>
      <router-view v-if='$route.meta.keepAlive'/>
    </keep-alive>
    <router-view v-if='!$route.meta.keepAlive'/>
  </div>
</template>

<script>
    // import HelloWorld from './components/HelloWorld.vue'
    import {
      getGetOpenId
    } from 'api/index'
    import { todoStorage } from 'libs/storage'
    // import chateId from 'conf/chatid'
    export default {
        name: 'app',
        components: {
            // HelloWorld
        },
        created () {
          // this.initFb(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
          // this.initWechat(window, document)
          if (todoStorage.getWxOpenid === null || todoStorage.getWxOpenid === 'undefined') {
            this.getCode()
          }
        },
        data () {
          return {
            code: ''
          }
        },
        mounted () {
          // this.getDataExcute(window.location.href.split('/')[2])
        },
        methods: {
          getCode () { // 非静默授权，第一次有弹框
            var local = window.location.href // 获取页面url
            const appid = 'wx40d7ba64097f8bcf' // 微信公号的开发者ID
            const url = local // 回调方法的url地址
            const UrlEncode = encodeURIComponent(url) // js的urlencode编码
            this.code = this.getUrlCode().code // 截取code
            this.state = this.getUrlCode().state // 我需要的参数值
            if (this.code == null || this.code === '') { // 如果没有code，则去请求
              window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + UrlEncode + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
            } else {
              getGetOpenId(this.code).then(res => {
                if (res.status === 'success') {
                  // 授权成功
                  todoStorage.setWxOpenid = res.data
                }
                console.log(res)
              })
            }
          },
          getUrlCode () { // 截取url中的code方法
            var url = location.search
            this.winUrl = url
            var theRequest = {
            }
            if (url.indexOf('?') !== -1) {
              var str = url.substr(1)
              var strs = str.split('&')
              for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
              }
            }
            return theRequest
          }
        }
      }
</script>

<style lang='stylus'>
  #app
    font-family 'Avenir', Helvetica, Arial, sans-serif
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    color #2c3e50
    background rgb(243, 243, 243) !important
    position absolute
    bottom 0
    left 0
    right 0
    top 0
    max-width: 768px;
    margin: auto;
</style>
