export const forEach = (arr, fn) => {
  if (!arr.length || !fn) {
      return
  }
  let i = -1
  let len = arr.length
  while (++i < len) {
    let item = arr[i]
    fn(item, i, arr)
  }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) {
        res.push(item)
    }
  }
  return res
}

// export const getUrlKey = (name) => {
//   // 获取url 参数
//   return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [ , ''])[1].replace(/\+/g, '%20')) || null
// }

//
// export const getCodeApi = (state) => {//获取code
//   let urlNow=encodeURIComponent(window.location.href);
//   let scope='snsapi_base';    //snsapi_userinfo   //静默授权 用户无感知
//   let appid='wx4cc5d5c123123123';
//   let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
//   window.location.replace(url);
// }

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([
    ...arr1,
    ...arr2
  ]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (target, arr) => {
  return target.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 * @param {String} key 用来验证的键值
 */
export function itemOfValue (value, validList, key) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i][key]) {
      return i
    }
  }
  return -1
}

export function cloneArray (myObj) {
  if (typeof (myObj) !== 'object' || myObj == null) {
      return myObj
  }
  var newObj
  if (myObj instanceof Array) {
    newObj = []
  }
  for (var i in myObj) {
  //   console.log(myObj[i])
  //   newObj[i] = cloneArray(myObj[i])
    newObj.push(myObj[i])
  }
  return newObj
}

// 验证是否整数
export function isInteger (rule, value, callback) {
  if (!value) {
    return callback(new Error('Please Input The Phone Number'))
  }
  setTimeout(() => {
    if (!Number(value)) {
      callback(new Error('Please Input Right Phone Number'))
    } else {
      const re = /^[0-9]*[1-9][0-9]*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('Please Input Right Phone Number'))
      } else {
        callback()
      }
    }
  }, 500)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf (value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true
    }
  }
  return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
  const timeStr = String(timeStamp)
  return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
  return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
  return num < 10
    ? '0' + num
    : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
  const d = new Date(timeStamp * 1000)
  const year = d.getFullYear()
  const month = getHandledValue(d.getMonth() + 1)
  const date = getHandledValue(d.getDate())
  const hours = getHandledValue(d.getHours())
  const minutes = getHandledValue(d.getMinutes())
  const second = getHandledValue(d.getSeconds())
  let resStr = ''
  if (startType === 'year') {
      resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
  } else {
      resStr = month + '-' + date + ' ' + hours + ':' + minutes
  }
  return resStr
}

/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */
export const getRelativeTime = timeStamp => {
  // 判断当前传入的时间戳是秒格式还是毫秒
  const IS_MILLISECOND = isMillisecond(timeStamp)
  // 如果是毫秒格式则转为秒格式
  if (IS_MILLISECOND) {
    Math.floor(timeStamp /= 1000)
    // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型
  }
  timeStamp = Number(timeStamp)
  // 获取当前时间时间戳
  const currentTime = Math.floor(Date.parse(new Date()) / 1000)
  // 判断传入时间戳是否早于当前时间戳
  const IS_EARLY = isEarly(timeStamp, currentTime)
  // 获取两个时间戳差值
  let diff = currentTime - timeStamp
  // 如果IS_EARLY为false则差值取反
  if (!IS_EARLY) {
      diff = -diff
  }
  let resStr = ''
  const dirStr = IS_EARLY
    ? '前'
    : '后'
  // 少于等于59秒
  if (diff <= 59) {
    resStr = diff + '秒' + dirStr
    // 多于59秒，少于等于59分钟59秒
  } else if (diff > 59 && diff <= 3599) {
    resStr = Math.floor(diff / 60) + '分钟' + dirStr
    // 多于59分钟59秒，少于等于23小时59分钟59秒
  } else if (diff > 3599 && diff <= 86399) {
    resStr = Math.floor(diff / 3600) + '小时' + dirStr
    // 多于23小时59分钟59秒，少于等于29天59分钟59秒
  } else if (diff > 86399 && diff <= 2623859) {
    resStr = Math.floor(diff / 86400) + '天' + dirStr
    // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
  } else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) {
    resStr = getDate(timeStamp)
  } else {
      resStr = getDate(timeStamp, 'year')
  }
  return resStr
}

/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer('MSIE')) {
    return 'IE'
  } else if (isExplorer('Firefox')) {
      return 'Firefox'
  } else if (isExplorer('Chrome')) {
      return 'Chrome'
  } else if (isExplorer('Opera')) {
      return 'Opera'
  } else if (isExplorer('Safari')) {
    return 'Safari'
  }
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()
