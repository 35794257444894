import axios from '@/libs/api.request'

// =============== news-system/articles/list.vue =========================

export const getCategories = () => {
    return axios.request({ url: '/api/categories/all', method: 'get' })
}

export const getShopinfo = (domain) => {
    return axios.request({
        url: '/api/shops/getshopbydomain',
        params: {
            domain: domain
        },
        method: 'get'
    })
}

export const getGetOpenId = (code) => {
  return axios.request({
    url: '/api/wx/getopenid',
    params: {
      code: code
    },
    method: 'get'
  })
}

export const pageView = (pagename) => {
    return axios.request({ url: '/api/pageview',
        data: {
            pagename: pagename
        },
        method: 'post' })
}
