import storage from 'store2'

// 缓存数据的key
const CART_LIST_DATA = 'CART_LIST_DATA'
const SELECT_CART_LIST_DATA = 'SELECT_CART_LIST_DATA'
const ORDER_PRODUCT_LIST_DATA = 'ORDER_PRODUCT_LIST_DATA'
const RECOMMEND_PRODUCT_LIST_DATA = 'RECOMMEND_PRODUCT_LIST_DATA'
const ORDER_DATA = 'ORDER_DATA'
const SHOP_DATA = 'SHOP_DATA'
const PIXEL_DATA = 'PIXEL_DATA'
const USER_DATA = 'USER_DATA'
const CURRENCY_DATA = 'CURRENCY_DATA'
const USER_LIST_DATA = 'USER_LIST_DATA'
const USER_ISLOGIN = 'USER_ISLOGIN'
const SELECT_NEWS = 'SELECT_NEWS'
const SELECT_PROJECT = 'SELECT_PROJECT'
const WX_OPENID = 'WX_OPENID'
export const todoStorage = {
    set setWxOpenid (val) {
      if (!val) {
        storage.remove(WX_OPENID)
      } else {
        storage.set(WX_OPENID, val)
      }
    },
    get getWxOpenid () {
      return storage.get(WX_OPENID)
    },
    set setSelectProject (val) {
      if (!val) {
        storage.remove(SELECT_PROJECT)
      } else {
        storage.set(SELECT_PROJECT, val)
      }
    },
    get getSelectProject () {
      return storage.get(SELECT_PROJECT)
    },
    set setSelectNews (val) {
      if (!val) {
        storage.remove(SELECT_NEWS)
      } else {
        storage.set(SELECT_NEWS, val)
      }
    },
    get getSelectNews () {
      return storage.get(SELECT_NEWS)
    },
    set setUserList (val) {
      if (!val) {
        storage.remove(USER_LIST_DATA)
      } else {
        storage.set(USER_LIST_DATA, val)
      }
    },
    get getUserList () {
      return storage.get(USER_LIST_DATA)
    },
    set setUserIsLogin (val) {
      if (!val) {
        storage.remove(USER_ISLOGIN)
      } else {
        storage.set(USER_ISLOGIN, val)
      }
    },
    get getUserIsLogin () {
      return storage.get(USER_ISLOGIN)
    },
    set setCartList (val) {
        if (!val) {
            storage.remove(CART_LIST_DATA)
        } else {
            storage.set(CART_LIST_DATA, val)
        }
    },
    get getCartList () {
        return storage.get(CART_LIST_DATA)
    },
    set setSelectCartList (val) {
        if (!val) {
            storage.remove(SELECT_CART_LIST_DATA)
        } else {
            storage.set(SELECT_CART_LIST_DATA, val)
        }
    },
    get getSelectCartList () {
        return storage.get(SELECT_CART_LIST_DATA)
    },
    set setUserData (val) {
        if (!val) {
            storage.remove(USER_DATA)
        } else {
            storage.set(USER_DATA, val)
        }
    },
    get getUserData () {
        return storage.get(USER_DATA)
    },

    set setOrderProductList (val) {
        if (!val) {
            storage.remove(ORDER_PRODUCT_LIST_DATA)
        } else {
            storage.set(ORDER_PRODUCT_LIST_DATA, val)
        }
    },
    get getOrderProductList () {
        return storage.get(ORDER_PRODUCT_LIST_DATA)
    },

    set setRecommendProductList (val) {
        if (!val) {
            storage.remove(RECOMMEND_PRODUCT_LIST_DATA)
        } else {
            storage.set(RECOMMEND_PRODUCT_LIST_DATA, val)
        }
    },
    get getRecommendProductList () {
        return storage.get(RECOMMEND_PRODUCT_LIST_DATA)
    },

    set setOrderItem (val) {
        if (!val) {
            storage.remove(ORDER_DATA)
        } else {
            storage.set(ORDER_DATA, val)
        }
    },

    get getOrderItem () {
        return storage.get(ORDER_DATA)
    },

    set setShopdata (val) {
        if (!val) {
            storage.remove(SHOP_DATA)
        } else {
            storage.set(SHOP_DATA, val)
        }
    },

    get getShopdata () {
        return storage.get(SHOP_DATA)
    },

    set setPixel (val) {
        if (!val) {
            storage.remove(PIXEL_DATA)
        } else {
            storage.set(PIXEL_DATA, val)
        }
    },

    get getPixel () {
        return storage.get(PIXEL_DATA)
    },

    set setCurrency (val) {
        if (!val) {
            storage.remove(CURRENCY_DATA)
        } else {
            storage.set(CURRENCY_DATA, val)
        }
    },

    get getCurrency () {
        return storage.get(CURRENCY_DATA)
    }
}
