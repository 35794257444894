<template>
    <div style="">
        <vheader></vheader>
    </div>
</template>

<script>
    import {
        getCategories
    } from 'api/index'

    import vheader from 'cp/v-header/header.vue'
    // import { todoStorage } from '@/libs/storage'
    export default {
        name: 'mainview',
        beforeRouteEnter (to, from, next) {
            next(vm => {
                document.title = 'Shopees Online Store'
            })
        },
        mounted () {
            // let t = this
            // t.getCategories()
        },
        components: {
            vheader
        },
        computed: {
            // cartnum: function () {
            //     var cartListData = todoStorage.getCartList
            //     if (cartListData) {
            //         return cartListData.length;
            //     } else {
            //         return 0;
            //     }
            // },
        },
        data () {
            return {
                cartPopshow: false,
                menuPopshow: false,
                newsCategories: []
            }
        },
        methods: {
            getCategories () {
                let t = this
                getCategories().then(res => {
                    t.newsCategories = res.data
                    console.log(res.data)
                })
            },
            cartIconClick () {
                var t = this
                var productid = 0
                t.$router.push({ name: 'cart', params: { productid: productid } })
            },
            userIconClick () {
                var t = this
                t.$router.push({ name: 'user' })
            }
        }
    }
</script>

<style lang="stylus" rel="stylesheet/stylus">
    @import "main.styl"
</style>
