import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import 'cm/stylus/index.styl'
import {
    pageView
} from 'api/index'
import VueLazyload from 'vue-lazyload'
require('jquery.scrollto')
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/logo.png',
    loading: '/loading.gif',
    attempt: 1
})

Vue.config.productionTip = false

Vue.prototype.showSpin = function (title) {
    this.toast = this.$createToast({
        txt: title,
        type: 'txt'
    })
    this.toast.show()
}

Vue.prototype.hideSpin = function () {
    this.toast.hide()
}

Vue.prototype.showLoading = function () {
    this.loadToast = this.$createToast({
        txt: 'Loading...',
        mask: true
    })
    this.loadToast.show()
}

Vue.prototype.hideLoading = function () {
    this.loadToast.hide()
}

Vue.prototype.backclick = function () {
    this.$router.go(-1)
}

Vue.prototype.pageViewSet = function (pagename) {
    pageView(pagename).then(res => {
    }, function (error) {
        console.log(error)
    })
}

Vue.prototype.NoteTips = function (title, txt) {
    this.toast = this.$createToast({
        txt: title,
        type: 'txt'
    })
    this.toast.show()
}

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
