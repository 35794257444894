<template>
  <div class="header">
    <div class="header_left oneitem_center">
<!--      <img src="../../assets/logo_h.png" alt="logo" >-->
      <h1>丰江促进会</h1>
<!--    </div>-->
<!--    <div class="header_right">-->
<!--      <cube-button icon="cubeic-person" :light="true" v-show="userShow" @click="goToUser"></cube-button>-->
<!--      <cube-button icon="cubeic-mall" :light="true" style="position: relative;" @click="goToCart"> <div class="topCartItem_idots oneitem_center" v-show="(cartProductNum > 0)">{{cartProductNum}}</div></cube-button>-->
      <!--<cube-button icon="cubeic-home" :light="true" v-show="userShow" @click="goToHome"></cube-button>-->
    </div>
  </div>
</template>

<script>

export default {
    name: 'v-header',
    props: {
      msg: String,
      homeShow: {
        type: Boolean,
        default: false
      },
      userShow: {
        type: Boolean,
        default: false
      },
      cartProductNum: {
        type: Number,
        default: 0
      }
    },
    methods: {
        goToCart () {
            this.$router.push({ name: 'cart', params: { productid: 0 } })
        },
        goToUser () {
            this.$router.push({ name: 'user' })
        },
        goToHome () {
            this.$router.push({ name: 'cart', params: { productid: 0 } })
        }
  },
  data () {
      return {
          logourl: '../../assets/logo_h.png'
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.topCartItem_idots
  width 20px
  height 20px
  border-radius 50%
  background-color red
  font-size 16px
  font-weight bold
  color white
  position: absolute;
  right: 7px;
  top: 6px;
  z-index: 1;
  padding 1px
h3
  margin 40px 0 0

ul
  list-style-type none
  padding 0

li
  display inline-block
  margin 0 10px

a
  color #42b983
.header
  display flex
  height 50px
  background white
  border-bottom 1px solid #e3e3e7
  .header_left
    flex 3
    height 100
    display: flex;
    align-items: center;
    margin-left 10px
    h1
      font-size: 1.1rem;
      font-weight: bold;
      color #C8001A
    img
      height 32px
  .header_right
    flex 1
    height 100%
    i
      font-size: 26px !important
    button
      height 100%
      width 60px
      float right
      font-size: 20px;
      i
        font-size: 26px !important
.cube-btn > i
  font-size: 26px !important
</style>
